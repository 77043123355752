<template>
  <div class="corporateInformation-view">
    <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
    >
      <el-form-item label="域名绑定" prop="websiteAddress">
        <el-input
            clearable
            v-model="ruleForm.websiteAddress"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入域名"
            maxlength="255"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="组织名称" prop="membersName">
        <el-input
            clearable
            v-model="ruleForm.membersName"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入组织名称"
            maxlength="200"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="LOGO" prop="logo">
        <el-upload :action="uploadUrl"
                   class="logo_con"
                   list-type="picture-card"
                   :class="{ disabled: uploadDisabled }"
                   :on-remove="handleRemove"
                   :on-success="handleSuccess"
                   :file-list="fileList"
                   :limit="1">
          <i slot="default" class="el-icon-plus"></i>
          <!--              <div slot="tip" class="el-upload__tip">上传图片</div>-->
        </el-upload>
        <div style="font-size: 12px;">建议上传LOGO图片尺寸为300*80px</div>
      </el-form-item>
      <el-form-item label="二维码" prop="qrCode">
        <el-upload :action="uploadUrl"
                   list-type="picture-card"
                   :class="{ disabled: uploadDisabled1 }"
                   :on-remove="handleRemove1"
                   :on-success="handleSuccess1"
                   :file-list="fileList1"
                   :limit="1">
          <i slot="default" class="el-icon-plus"></i>
          <!--              <div slot="tip" class="el-upload__tip">上传图片</div>-->
        </el-upload>
      </el-form-item>
      <el-form-item label="联系人" prop="people">
        <el-input
            clearable
            v-model="ruleForm.people"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入联系人"
            maxlength="11"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
            clearable
            v-model="ruleForm.phone"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入联系电话"
            maxlength="255"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="QQ邮箱" prop="qq">
        <el-input
            clearable
            v-model="ruleForm.qq"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入QQ邮箱"
            maxlength="300"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input
            clearable
            v-model="ruleForm.address"
            style="width: 240px; margin-right: 8px"
            placeholder="请输入地址"
            maxlength="300"
            show-word-limit
        ></el-input>
      </el-form-item>
      <!--          <el-form-item label="SSL证书key" prop="certData">-->
      <!--              <el-upload-->
      <!--                  class="upload-demo"-->
      <!--                  :action="uploadUrl"-->
      <!--                  :before-upload="beforeUpload"-->
      <!--                  :before-remove="beforeRemove"-->
      <!--                  :limit="1"-->
      <!--                  :on-exceed="handleExceed"-->
      <!--                  :file-list="fileList2">-->
      <!--                <el-button size="small" type="primary">点击上传</el-button>-->
      <!--                <div slot="tip" class="el-upload__tip">只能p12证书,从微信支付商户获取</div>-->
      <!--              </el-upload>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item label="SSL证书秘钥" prop="certData1">-->
      <!--              <el-upload-->
      <!--                  class="upload-demo"-->
      <!--                  :action="uploadUrl"-->
      <!--                  :before-upload="beforeUpload1"-->
      <!--                  :before-remove="beforeRemove1"-->
      <!--                  :limit="1"-->
      <!--                  :on-exceed="handleExceed1"-->
      <!--                  :file-list="fileList3">-->
      <!--                <el-button size="small" type="primary">点击上传</el-button>-->
      <!--                <div slot="tip" class="el-upload__tip">只能p12证书,从微信支付商户获取</div>-->
      <!--              </el-upload>-->
      <!--          </el-form-item>-->
      <el-form-item>
        <el-button
            class="save"
            type="primary"
            @click="submitForm('ruleForm')"
        >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {selectOfficialWebsitePageList,addOfficialWebsite,upOfficialWebsite} from '@/api/website'
export default {
  name: "basicSetup",
  data() {
    return {
      activeName: "first",
      disabled: false,
      ruleForm: {
        membersName: "",
        people: "",
        phone: "",
        qq: "",
        address: "",
        websiteAddress: "",
        qrCode:"",
        logo:"",
        certData: '',
        certData1: '',
      },
      rules: {
        logo: [{ required: true, message: "请上传logo" }],
        qrCode: [{ required: true, message: "请上传二维码" }],
        membersName: [{ required: true, message: "请输入商协会名称", trigger: "blur" }],
        people: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" },
          {
            type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        // certData: [
        //   { required: true, message: "请上传支付证书", trigger: "blur" },
        // ],
        // certData1: [
        //   { required: true, message: "请上传支付证书", trigger: "blur" },
        // ],
      },
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      fileList:[],
      fileList1:[],
      updateData: 2,
      officialWebsiteId:'',
      fileList2:[],
      fileList3:[],
    };
  },
  mounted(){
    this.getCompany()
  },
  computed: {
    uploadDisabled(){
      return this.fileList.length !== 0;
    },
    uploadDisabled1(){
      return this.fileList1.length !== 0;
    },
  },
  methods: {

    handleExceed(){

    },
    beforeUpload(file){
      console.log(file)
      return
      const that = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e){
        let base64Data = e.target.result;
        base64Data = base64Data.substring(base64Data.indexOf(',')+1);
        that.ruleForm.certData = base64Data;
      };
    },
    beforeRemove(){

    },
    handleExceed1(){

    },
    beforeUpload1(file){
      console.log(file)
      const that = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e){
        let base64Data = e.target.result;
        base64Data = base64Data.substring(base64Data.indexOf(',')+1);
        that.ruleForm.certData1 = base64Data;
      };
    },
    beforeRemove1(){

    },



    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = []
      this.ruleForm.logo = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log('file',file)
      this.ruleForm.logo = this.ossUrl +  file.response.data
      this.fileList = fileList;
    },
    handleRemove1(file, fileList) {
      this.fileList1 = []
      this.ruleForm.qrCode = ''
    },
    handleSuccess1(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log('file',file)
      this.ruleForm.qrCode = this.ossUrl +  file.response.data
      this.fileList1 = fileList;
    },
    /**@method 提交表单 */
    submitForm(formName) {
      if ( this.ruleForm.websiteAddress ) {
        var reg=/(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/;
        if (!reg.test(this.ruleForm.websiteAddress)){
          this.$message({
            message: "请输入正确的网址",
            type: "error",
          });
          return
        }
      }
      if (this.ruleForm.qq) {
        var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if(!reg.test(this.ruleForm.qq)){
          this.$message({
            message: "请输入有效的邮箱",
            type: "error",
          });
          return;
        }
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ajax = {
            associationId:localStorage.getItem("associationId"),
            associationName: this.ruleForm.membersName,
            logo:this.ruleForm.logo,
            qr:this.ruleForm.qrCode,
            userName:this.ruleForm.people,
            phone:this.ruleForm.phone,
            address:this.ruleForm.address,
            qq:this.ruleForm.qq,
            websiteUrl:this.ruleForm.websiteAddress,
          }
          if (this.updateData == 1) {
            ajax.officialWebsiteId = this.officialWebsiteId
            upOfficialWebsite(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCompany()
                  }
                })
          } else if (this.updateData == 2) {
            addOfficialWebsite(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCompany()
                  }
                })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取公司信息
    getCompany(){
      let ajax = {
        associationId: localStorage.getItem("associationId")
      }
      selectOfficialWebsitePageList(ajax)
          .then(res=>{
            if (res.data) {
              this.updateData = 1
              let data = res.data.pageInfo.list[0]
              if (data.logo) {
                this.fileList = [{url: data.logo, uid: 1}]
              }
              if (data.qr) {
                this.fileList1 = [{url: data.qr, uid: 2}]
              }
              this.ruleForm.membersName = data.associationName
              this.ruleForm.logo = data.logo
              this.ruleForm.qrCode = data.qr
              this.ruleForm.people = data.userName
              this.ruleForm.phone = data.phone
              this.ruleForm.qq = data.qq
              this.ruleForm.address = data.address
              this.ruleForm.websiteAddress = data.websiteUrl
              this.officialWebsiteId = data.officialWebsiteId
            }
          })
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
.corporateInformation-view {
  padding: 16px 0;
  background: #ffffff;
}
.ruleForm {
  margin-top: 24px;
}
.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}
.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 0px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 84px;
  height: 34px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep{
  .logo_con{
    .el-upload-list__item {
      width: 300px;
      height: 80px;
    }
    .el-upload--picture-card{
      width: 300px;
      height: 80px;
      line-height: 98px;
    }
  }
}
</style>

