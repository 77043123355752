<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本设置" name="first">
        <basicSetup v-if="activeName == 'first'"  :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="网站页脚设置" name="second">
        <websiteSetup v-if="activeName == 'second'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="轮播图设置" name="third">
        <swiperSetup v-if="activeName == 'third'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import basicSetup from "./step/basicSetup.vue";
import websiteSetup from "./step/websiteSetup.vue";
import swiperSetup from "./step/swiperSetup.vue";
import { mapState } from 'vuex';

export default {
  name: "dynamicList",
  components: {
    basicSetup,
    websiteSetup,
    swiperSetup
  },

  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),

  },
  created(){
    if(this.$route.query.name){
      this.activeName = this.$route.query.name
    }
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("040")
      console.log(this.jurisdiction,'this.jurisdiction')
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>



