<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-select
            v-model="filterName"
            class="w220 mb10 mr10"
            placeholder="请选择模板"
            clearable
        >
          <el-option
              v-for="(item, index) in mubanList"
              :key="index"
              :label="item.templateTitle"
              :value="item.templateSettingsId"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button
            class="mr10"
            type="primary"
            @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
        >+ 新增</el-button>
      </div>
    </div>

    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="imgName" align="center" label="名称" show-overflow-tooltip/>
        <el-table-column prop="title" align="center" label="模板" show-overflow-tooltip/>
        <el-table-column prop="createTime" align="center" label="创建时间" show-overflow-tooltip/>
        <el-table-column  align="center" label="banner">
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.url"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="jump" align="center" label="跳转链接" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.sort" min="0" @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button
            >
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row.imgId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <el-dialog
        title="新增"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="名称" t prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="banner图" prop="coverImg">
          <el-upload :action="$store.state.uploadingUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <!--              上传图片的最佳尺寸：4:9，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M-->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort" min="0" @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="跳转链接" prop="link">
          <el-input clearable v-model="ruleForm.link" placeholder="请输入URL..." maxlength="255" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="请选择模板" prop="template">
          <el-select
              clearable
              v-model="ruleForm.template"
              style="width: 100%"
              placeholder="请选择模板"
          >
            <el-option
                v-for="(item,index) in mubanList"
                :key="index"
                :label="item.templateTitle"
                :value="item.templateSettingsId"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {queryAllTemplateSettings,addAssWebsiteBanner,queryPageAssWebsiteBanner,updateAssWebsiteBanner,delAssWebsiteBanner} from '@/api/website'
let ac;
export default {
  name:'swiperSetup',
  data() {
    return {
      filterName:'',
      srcList:[],
      mubanList:[],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        sort: "",
        link:'',
        coverImg:'',
        template:''
      },
      rules: {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传banner图片" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        template: [{ required: true, message: "请选择模板", trigger: "blur" }],
      },
      imgId:'',
      ossUrl: this.$store.state.ossUrl,
      options:[],
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted(){
    //查询模板
    this.queryMB()
  },

  methods: {
    async queryMB () {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await queryAllTemplateSettings(data);
        let list = result.data;
        this.mubanList = list
        this.queryPage();
      } catch (error) {
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryPageAssWebsiteBanner(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        let a = []
        for (let i in this.mubanList) {
          for (let j in list) {
            if (list[j].url) {
              a.push(list[j].url)
            }
            list[j].createTime = this.renderTime(list[j].createTime)
            if (list[j].templateSettingsId == this.mubanList[i].templateSettingsId) {
              list[j]['title'] = this.mubanList[i].templateTitle
            }
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.coverImg = "";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.imgName;
      this.ruleForm.sort = data.sort;
      this.ruleForm.template = data.templateId;
      this.ruleForm.link = data.jump;
      this.ruleForm.coverImg = data.url;
      this.fileList = [{ url: data.url, uid: 1 }];
      this.bdetermine = b;
      this.bEdit = b;
      this.imgId = data.imgId
    },
    /**@method 添加视频 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              imgName:this.ruleForm.title,
              url:this.ruleForm.coverImg,
              sort:this.ruleForm.sort,
              jump:this.ruleForm.link,
              templateId:this.ruleForm.template,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.imgId = this.imgId
              await updateAssWebsiteBanner(data);
            } else {
              await addAssWebsiteBanner(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delAssWebsiteBanner({ imgId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          sort:row.sort,
          imgId:row.imgId
        }
        const result = await updateAssWebsiteBanner(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
        link:'',
        coverImg:'',
        template: ''
      }),
          this.centerDialogVisible = false;
      this.fileList = []
    },
    filterNames() {
      this.currentPage = 1;
      let data = {
        templateId: this.filterName
      };
      this.queryPage(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>



