<template>
  <div class="corporateInformation-view">
    <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
    >
      <el-form-item label="版权所属" prop="banquan">
        <el-input
            clearable
            v-model="ruleForm.banquan"
            style="width: 500px; margin-right: 8px"
            placeholder="请输入版权所属"
            maxlength="200"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="网站备案号" prop="fortherecord">
        <el-input
            clearable
            v-model="ruleForm.fortherecord"
            style="width: 500px; margin-right: 8px"
            placeholder="请输入网站备案号"
            maxlength="200"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="网站声明" prop="wzStatement">
        <el-input
            :rows="5"
            type="textarea"
            clearable
            v-model="ruleForm.wzStatement"
            style="width: 500px; margin-right: 8px"
            placeholder="请输入网站声明"
            maxlength="2000"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="免责声明" prop="mzStatement">
        <el-input
            :rows="5"
            type="textarea"
            clearable
            v-model="ruleForm.mzStatement"
            style="width: 500px; margin-right: 8px"
            placeholder="请输入免责声明"
            maxlength="2000"
            show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
            class="save"
            type="primary"
            @click="submitForm('ruleForm')"
        >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {selectOfficialWebsitePageList,addOfficialWebsite,upOfficialWebsite} from '@/api/website'
export default {
  name: "websiteSetup",
  data() {
    return {
      disabled: false,
      ruleForm: {
        banquan:"",
        fortherecord: "",
        wzStatement: "",
        mzStatement: "",
      },
      rules: {
        banquan: [{ required: true, message: "请输入版权所属", trigger: "blur" }],
        fortherecord: [{ required: true, message: "请输入网站备案号", trigger: "blur" }],
        wzStatement: [{ required: true, message: "请输入网站声明", trigger: "blur" }],
        mzStatement: [{ required: true, message: "请输入免责声明", trigger: "blur" }],
      },
      updateData: 2,
      officialWebsiteId:''
    };
  },
  mounted(){
    this.getCompany()
  },
  methods: {
    /**@method 提交表单 */
    submitForm(formName) {
      console.log('000')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('111')
          let ajax = {
            associationId:localStorage.getItem("associationId"),
            keepRecord:this.ruleForm.fortherecord,
            websiteStatement:this.ruleForm.wzStatement,
            disclaimers:this.ruleForm.mzStatement,
            copyright:this.ruleForm.banquan,
          }
          if (this.updateData == 1) {
            ajax.officialWebsiteId = this.officialWebsiteId
            upOfficialWebsite(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCompany()
                  }
                })
          } else if (this.updateData == 2) {
            console.log(ajax)
            addOfficialWebsite(ajax)
                .then(res => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.getCompany()
                  }
                })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取公司信息
    getCompany(){
      let ajax = {
        associationId: localStorage.getItem("associationId")
      }
      selectOfficialWebsitePageList(ajax)
          .then(res=>{
            if (res.data) {
              let data = res.data.pageInfo.list[0]
              this.updateData = 1
              this.officialWebsiteId = data.officialWebsiteId
              this.ruleForm.fortherecord = data.keepRecord
              this.ruleForm.wzStatement =  data.websiteStatement
              this.ruleForm.mzStatement =  data.disclaimers
              this.ruleForm.banquan =  data.copyright
            }
          })
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
.corporateInformation-view {
  padding: 16px 0;
  background: #ffffff;
}
.ruleForm {
  margin-top: 24px;
}
.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}
.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 0px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 84px;
  height: 34px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>


